import React, { useState, useEffect } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../firebase";
import toast from "react-hot-toast";
import axios from "axios";
import OTPInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../Redux/userActions";

const PhoneVerification = ({handleClick}) => {
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let interval;
    if (timer > 0 && showOtpInput) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsResendEnabled(true);
    }
    return () => clearInterval(interval);
  }, [timer, showOtpInput]);

  const initializeRecaptcha = () => {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
    }
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: () => {
          toast.success("reCAPTCHA verified");
        },
        defaultCountry: "IN",
      },
      auth
    );
  };

  const onSignIn = (e) => {
    e.preventDefault();
    if (mobile.length !== 10) {
      toast.error("Please enter a valid 10-digit mobile number.");
      return;
    }

    setIsLoading(true);
    initializeRecaptcha();
    const phoneNumber = "+91" + mobile;
    const appVerifier = window.recaptchaVerifier;
    handleClick("User", "Contact No Login", "Login Form", 1);
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult; // Store confirmation result
        setShowOtpInput(true);
        setTimer(60); // Reset timer
        setIsResendEnabled(false);
        toast.success("OTP sent successfully!");
        setIsLoading(false); // Set loading false after OTP is sent
      })
      .catch((error) => {
        console.error("Error sending OTP:", error.message);
        toast.error("Error sending OTP. Please try again.");
        setIsLoading(false); // Set loading false in case of error
      });
  };

  const handleResend = () => {
    if (isResendEnabled) {
      // Reset reCAPTCHA and resend OTP
      window.recaptchaVerifier.clear();
      initializeRecaptcha();
      onSignIn(new Event("resend")); // Trigger the sign-in process again
    }
  };

  const onSubmitOtp = async (e) => {
    e.preventDefault();
    if (otp.length !== 6) {
      toast.error("Please enter a valid 6-digit OTP.");
      return;
    }

    if (window.confirmationResult) {
      setIsLoading(true); // Set loading true during OTP verification
      window.confirmationResult
        .confirm(otp)
        .then(async (result) => {
          const user = result.user;
          const uid = await user.getIdToken();
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL_IVAR_NOT_KNOWN}/api/auth/phone`,
            { phoneNumber: "+91-" + mobile, uid }
          );

          if (response.data.success) {
            const { user, token } = response.data;
            dispatch(setUser(user, token));
            toast.dismiss();
            toast.success("Login successful!");
            setTimeout(() => {
              navigate("/cart");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }, 500);
          } else {
            toast.error("Failed to verify OTP with the backend.");
          }
          setIsLoading(false); // Set loading false after successful OTP verification
        })
        .catch((error) => {
          console.error("Error verifying OTP:", error.message);
          toast.error("Invalid OTP. Please try again.");
          setIsLoading(false); // Set loading false in case of error
        });
    } else {
      toast.error("Please try again, OTP confirmation has failed.");
      setIsLoading(false); // Set loading false in case of error
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold text-center text-gray-700">
        Phone Authentication
      </h1>

      {!showOtpInput ? (
        <>
          <h2 className="text-xl font-semibold text-gray-700 mt-6">
            Enter Mobile Number
          </h2>
          <form onSubmit={onSignIn} className="mt-4">
            <div id="sign-in-button"></div>
            <input
              type="text"
              name="mobile"
              placeholder="Mobile number"
              required
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mt-2"
            />
            <button
              type="submit"
              className="w-full mt-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              disabled={isLoading} // Disable button when loading
            >
              {isLoading ? "Sending OTP..." : "Send OTP"}
            </button>
          </form>
        </>
      ) : (
        <>
          <h2 className="text-xl font-semibold text-gray-700 mt-6">
            Enter OTP
          </h2>
          <form onSubmit={onSubmitOtp} className="mt-4">
            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              isInputNum
              shouldAutoFocus
              renderInput={(props) => (
                <input
                  {...props}
                  className="w-[8rem] md:w-[10rem] lg:w-[12rem] h-10 mx-1 text-xl text-center border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
                />
              )}
            />
            <button
              type="submit"
              className="w-full mt-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
              disabled={isLoading} // Disable button when loading
            >
              {isLoading ? "Verifying OTP..." : "Verify OTP"}
            </button>
          </form>
          <div className="text-center mt-4">
            {isResendEnabled ? (
              <button
                onClick={handleResend}
                className="text-blue-500 hover:text-blue-700"
              >
                Resend OTP
              </button>
            ) : (
              <p className="text-gray-500">
                Resend OTP in {timer} second{timer > 1 ? "s" : ""}
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PhoneVerification;
