import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import circle from "./Images/circle.svg";
import rcircle from "./Images/rcircle.svg";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { addToCart } from "../Redux/cartActions";
import { PayCod } from "../service/PayCod";
import { initiatePayment } from "../service/PayOnline";
import axios from "axios";
import "./style.css";
let abortController;

const PaymentPage = ({ handleClick }) => {
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);
  const cartItems = useSelector((state) => state.cart.items || []);
  if (!user || !cartItems) {
    navigate("/login");
  }
  const addTotal = () => {
    let tot = 0;
    // tw = 0;
    cartItems.forEach((item) => {
      tot += item.price * item.quantity;
      // tw += item.netWeight * item.price * item.quantity;
    });
    setTotal(tot);
    if (tot <= 500) setDeliveryCharge(39);
    else setDeliveryCharge(0);
    // setTotalWeight(tw);
    // console.log(tw);
  };

  // const [totalWeight, setTotalWeight] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [codCharge, setCodCharge] = useState(0);
  const [orderPlaced, setOrderPlaced] = useState(false);
  useEffect(() => {
    addTotal();
  }, [cartItems]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: user.name || "",
    email: user.email || "",
    contactNo: user.contactNo || "",
    pincode: user.pincode || "",
    state: user.state || "",
    district: user.district || "",
    address: user.address || "",
    paymentMethod: "",
    paymentId: "",
  });
  // const isMobile = window.innerWidth < 768;
  const language = useSelector((state) => state.language.language);
  const dispatch = useDispatch();
  // let tw = 0;
  const fetchPincodeData = async (value) => {
    try {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${Number(value)}`
      );
      const postOffices = response.data[0]?.PostOffice;
      if (postOffices && postOffices.length > 0) {
        const district = postOffices[0].District;
        const state = postOffices[0].State;
        setFormData({
          ...formData,
          district: district,
          state: state,
          pincode: Number(value),
        });
      } else {
        // console.log("No post office found for this pincode.");
      }
    } catch (error) {
      // console.error("Error fetching pincode data:", error);
    }
  };

  // const scrollToHead = () => {
  //   if (headRef.current) {
  //     headRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const handleRemove = (item) => {
    setTotal(0);
    // setTotalWeight(0);
    item.quantity = 0;
    dispatch(addToCart({ ...item, quantity: 0 }));
  };

  const formatDecimals = (num) => parseFloat(num).toFixed(0);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "paymentMethod") {
      setCodCharge(value === "COD" ? 27 : 0);
    } else if (name === "contactNo") {
      if (Number(value) > 9999999999) {
        toast.error("Mobile number must be 10 digits.");
      }
    } else if (name === "pincode") {
      if (value.length > 6) {
        toast.error("Pincode should be 6 digits");
      }
      if (value.length === 6) {
        // const pin = [30, 31, 32, 33, 34, 12, 13, 20, 11];
        // const price1 = [41, 81, 121, 131, 161];
        // const price2 = [62, 121, 181, 201, 251];
        // const digi = Number(value[0] + value[1]);

        // setDeliveryCharge(
        //   pin.includes(digi)
        //     ? price1[Math.floor((totalWeight * 1000 - 1) / 500)]
        //     : price2[Math.floor((totalWeight * 1000 - 1) / 500)]
        // );
        fetchPincodeData(value);
      }
    }
  };
  const placed = () =>
    orderPlaced ? "bg-white text-blue-500" : "bg-blue-500 text-white";
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (orderPlaced) {
      toast.error("Please fill the cart.");
      return;
    }
    if (!formData.paymentMethod) {
      toast.error("Please choose a payment method.");
      return;
    }
    if (formData.contactNo > 9999999999 || formData.contactNo < 1000000000) {
      toast.error("Mobile number must be 10 digits.");
      return;
    }

    let totalAmount = formatDecimals(deliveryCharge + total + 0 + codCharge);
    setLoading(true);
    const dataToSubmit = {
      ...formData,
      paymentId: "",
      total: totalAmount,
      cartItems: JSON.stringify(
        cartItems.map((item) => ({
          title: item.title,
          price: item.price,
          quantity: item.quantity,
        }))
      ),
    };

    try {
      let response;
      if (abortController) {
        abortController.abort();
      }
      abortController = new AbortController();

      if (formData.paymentMethod === "COD") {
        handleClick("User", "Payment COD", "Payment", 1);
        response = await PayCod(
          formData,
          cartItems,
          dataToSubmit,
          deliveryCharge,
          totalAmount,
          user,
          setFormData,
          token,
          navigate,
          dispatch,
          abortController
        );
      } else {
        handleClick("User", "Payment Online", "Payment", 1);
        response = await initiatePayment(
          formData,
          cartItems,
          dataToSubmit,
          deliveryCharge,
          totalAmount,
          user,
          setFormData,
          token,
          navigate,
          dispatch,
          abortController
        );
      }
      if (response === "success") {
        setOrderPlaced(true);
        cartItems.forEach(handleRemove);
        setTimeout(() => {
          navigate("/dashboard");
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 2000);
      } else {
        throw new Error(response.data.message || "Unknown error");
      }
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  // const [discount, setDiscount] = useState(0);
  // useEffect(() => {
  //   const fetchPaymentData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_BACKEND_URL_IVAR_NOT_KNOWN}/api/dashboard`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             email: user.email,
  //           },
  //         }
  //       );
  //       if (response.data.length === 0) {
  //         setDeliveryCharge(0);
  //         setDiscount(0.3);
  //       }
  //     } catch (error) {
  //       // Handle error if needed
  //       // console.error("Error fetching payment data:", error);
  //     }
  //   };

  //   fetchPaymentData();
  // }, []);
  return (
    <div>
      <div
        className="md:mt-16 mt-28 mb-10 md:mb-0"
        style={{
          minHeight: "400px",
          background:
            "linear-gradient(140deg, rgba(245, 130, 52, 0.31), rgba(245, 130, 52, 0.31))",
        }}
      >
        <div className="relative">
          {
            <div className="sm:flex absolute -left-0 -top-0 -z-10 hidden items-center justify-center">
              <img
                src={rcircle}
                alt="Circle decoration"
                className="mix-blend-color-burn"
                style={{ width: "300px", height: "auto" }}
              />
            </div>
          }
          <div className="absolute -right-0 -top-0 md:z-auto -z-10 flex items-center justify-center">
            <img
              src={circle}
              alt="Circle decoration"
              className="mix-blend-color-burn"
              style={{ width: "300px", height: "auto" }}
            />
          </div>

          <div className="mt-0">
            {/* <div className="max-w-md mx-auto p-4"> */}
            <div className="max-w-full sm:max-w-md mx-auto p-4">
              <form onSubmit={handleSubmit}>
                {/* User Details Section */}
                <div className="border-b-2 border-black pb-4 mb-4 mt-4">
                  <h2 className="md:text-3xl text-xl font-bold mb-4 mt-3">
                    {language === 0 ? "Details" : "डिटेल्स"}
                  </h2>
                  <div className="grid gap-4">
                    <input
                      type="text"
                      name="name"
                      placeholder="Full Name"
                      required
                      value={formData.name}
                      onChange={handleChange}
                      className="p-2 border-2 border-black rounded placeholder:text-sm md:placeholder:text-base"
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                      className="p-2 border-2 border-black rounded placeholder:text-sm md:placeholder:text-base"
                    />
                    <input
                      type="number"
                      min="0"
                      name="contactNo"
                      placeholder="Mobile Number"
                      value={formData.contactNo}
                      onChange={handleChange}
                      className="p-2 border-2 border-black rounded placeholder:text-sm md:placeholder:text-base"
                      disabled
                    />
                  </div>
                </div>

                {/* Address Section */}
                <div className="border-b-2 border-black pb-4 mb-4 mt-4">
                  <h2 className="md:text-3xl text-xl font-bold mb-4 mt-3">
                    {language === 0 ? "Address" : "पता"}
                  </h2>
                  <div className="grid gap-4">
                    <input
                      type="number"
                      name="pincode"
                      placeholder="Pincode"
                      value={formData.pincode}
                      onChange={handleChange}
                      className="p-2 border-2 border-black rounded placeholder:text-sm md:placeholder:text-base"
                      required
                    />
                    <input
                      type="text"
                      name="state"
                      required
                      placeholder="State"
                      value={formData.state}
                      onChange={handleChange}
                      className="p-2 border-2 border-black rounded placeholder:text-sm md:placeholder:text-base"
                    />
                    <input
                      type="text"
                      name="district"
                      required
                      placeholder="District"
                      value={formData.district}
                      onChange={handleChange}
                      className="p-2 border-2 border-black rounded placeholder:text-sm md:placeholder:text-base"
                    />
                    <input
                      type="text"
                      name="address"
                      required
                      placeholder="Flat, House no., Building, Company, Apartment"
                      value={formData.address}
                      onChange={handleChange}
                      className="p-2 border-2 border-black rounded placeholder:text-sm md:placeholder:text-base"
                    />
                  </div>
                </div>

                {/* Payment Method Section */}
                <div className="border-b-2 border-black pb-4 mb-4 mt-4">
                  <h2 className="md:text-3xl text-xl font-bold mb-4 mt-3">
                    {language === 0 ? "Payment Method" : "भुगतान विधि"}
                  </h2>

                  <div className="flex items-center md:text-xl justify-between text-lg font-semibold my-4">
                    <input
                      type="radio"
                      id="paymentMethodCOD"
                      name="paymentMethod"
                      value="COD"
                      checked={formData.paymentMethod === "COD"}
                      onChange={handleChange}
                      className="hidden placeholder:text-sm md:placeholder:text-base"
                    />
                    <label
                      htmlFor="paymentMethodCOD"
                      className="flex items-center cursor-pointer"
                    >
                      <div
                        className={`w-6 h-6 border-2 text-xs ${
                          formData.paymentMethod === "COD"
                            ? "border-blue-500"
                            : "border-black"
                        } rounded-full flex items-center justify-center mr-2`}
                      >
                        {formData.paymentMethod === "COD" && (
                          <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                        )}
                      </div>
                      Cash on Delivery (COD)
                    </label>
                    <div className="flex justify-between text-sm">
                      <span>COD Charge:</span>
                      <span>{formatDecimals(27)} ₹</span>
                    </div>
                  </div>
                  <div className="flex items-center md:text-xl text-lg font-semibold">
                    <input
                      type="radio"
                      id="paymentMethodOnline"
                      name="paymentMethod"
                      value="online"
                      checked={formData.paymentMethod === "online"}
                      onChange={handleChange}
                      className="hidden placeholder:text-sm md:placeholder:text-base"
                    />
                    <label
                      htmlFor="paymentMethodOnline"
                      className="flex items-center cursor-pointer"
                    >
                      <div
                        className={`w-6 h-6 border-2 ${
                          formData.paymentMethod === "online"
                            ? "border-blue-500"
                            : "border-black"
                        } rounded-full flex items-center justify-center mr-2`}
                      >
                        {formData.paymentMethod === "online" && (
                          <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                        )}
                      </div>
                      Online Payment
                    </label>
                  </div>
                </div>

                {/* Order Summary Section */}
                <div className="border-b-2 border-black pb-4 mb-4 mt-4">
                  <h2 className="md:text-3xl text-xl font-bold mb-4 mt-3">
                    {language === 0 ? "Order Summary" : "ऑर्डर का सारांश"}
                  </h2>
                  <div className="flex justify-between mb-4">
                    <span>Subtotal:</span>
                    <span>{formatDecimals(total)} ₹</span>
                  </div>
                  <div className="flex justify-between mb-4">
                    <span>Delivery Charge:</span>
                    <span>{formatDecimals(deliveryCharge)} ₹</span>
                  </div>
                  <div className="flex justify-between mb-4">
                    <span>COD Charge:</span>
                    <span>{formatDecimals(codCharge)} ₹</span>
                  </div>
                  <div className="flex justify-between mb-4">
                    <span>Service Charge:</span>
                    <span>{formatDecimals(0)} ₹</span>
                  </div>
                  {/* {discount > 0 && (
                    <div className="flex justify-between mb-4">
                      <span>Discount:</span>
                      <span>-{formatDecimals(discount * total)} ₹</span>
                    </div>
                  )} */}
                  <div className="flex justify-between font-bold">
                    <span>Total:</span>
                    <span>
                      {formatDecimals(deliveryCharge + total + codCharge + 0)} ₹
                    </span>
                  </div>
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  className={`w-full p-2 rounded-md mt-6 ${placed()} transition-all duration-300 ease-in-out`}
                >
                  {loading ? "Processing..." : "Place Order"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
