// src/redux/rootReducer.js
import { combineReducers } from 'redux';
import cartReducer from './cartReducer';
import languageReducer from './languageReducer';
import userReducer from './userReducer'; // Import userReducer

const rootReducer = combineReducers({
  cart: cartReducer,           // Manage cart-related state
  language: languageReducer,   // Manage language-related state
  user: userReducer,           // Manage user-related state
});

export default rootReducer;
