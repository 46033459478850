import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux"; // Import Provider from react-redux
import App from "./App";
import store from './Redux/store'; // Import the Redux store
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}> {/* Wrap the app with Redux Provider */}
        <BrowserRouter>
          <App />
          <Toaster />
        </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

