import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleLanguage } from "../Redux/languageActions";
import toast from "react-hot-toast";
import logo from "./Images/logo.svg";
import cartl from "./Images/cart.svg";
import wb from "./Images/whatsapp.svg";
import login from "./Images/login.svg";
const Navbar = () => {
  const cartItems = useSelector((state) => state.cart.items || []);
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const arr = ["हिन्दी", "English"];
  const [totalItem, setTotalItem] = useState(0);

  const getAll = () => {
    let ans = 0; // Use let instead of const here
    cartItems.forEach((item) => {
      ans += item.quantity; // Ensure item has a quantity property
    });
    setTotalItem(ans);
  };

  useEffect(() => {
    getAll();
  }, [cartItems]);
  const handleClick = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };
  const handleClickHome = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // const youtubeVideo = () => {
  //   toast("Video will be uploaded soon", {
  //     style: {
  //       background: "red",
  //       color: "white",
  //       padding: "1rem",
  //       fontWeight: "bold",
  //     },
  //   });
  // };
  return (
    <div className="font-semibold  md:px-5 shadow-md fixed w-full top-0 z-10 bg-white ">
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;400;600;800&display=swap');

          body {
            font-family: 'Plus Jakarta Sans', sans-serif;
          }

          .navbar {
            padding: 1rem; 
            padding-top:0.5rem;
          }

          .navbar p {
            margin: 0;
          }

          .navbar a {
            text-decoration: none;
            position: relative;
          }

          .navbar .c:hover, #lang:hover {
            text-decoration: underline;
            text-underline-offset: 0.4rem;
            text-decoration-thickness: 2px;
            transition: color 0.2s ease, text-decoration 0.2s ease;
          }

          .navbar img {
            // vertical-align: middle;
          }
        `}
      </style>
      <div className="navbar flex justify-between items-center">
        <div className="flex items-center ">
          <Link to="/" onClick={handleClickHome}>
            <img
              src={logo}
              alt="Logo"
              className="md:h-10 h-8 md:w-auto max-w-max "
            />
          </Link>
          <Link to="/" onClick={handleClickHome} className="no-underline">
            <h1 className=" text-orange-500 ml-3 md:text-xl text-base hover:no-underline no-underline">
              ParamBhog
            </h1>
          </Link>

          <div className="ml-10 sm:mr-14  md:ml-28 flex-col cursor-pointer">
            <p
              id="lang"
              className="font-semibold hover:text-orange-500"
              onClick={() => dispatch(toggleLanguage())}
            >
              {arr[language]}
            </p>
          </div>
        </div>
        <div className="hidden  md:flex space-x-7 font-semibold">
          <Link
            to="/"
            onClick={handleClickHome}
            className="c hover:text-orange-500"
          >
            Home
          </Link>
          <Link
            to="/"
            onClick={handleClick}
            className="c hover:text-orange-500"
          >
            About Us
          </Link>
          <Link
            to="/"
            onClick={handleClick}
            className="c hover:text-orange-500"
          >
            Feedback
          </Link>
        </div>
        <div className="flex gap-7">
          <div className=" hidden md:flex lg:flex xl:flex 2xl:flex  items-center gap-6">
            <img src={wb} alt="WhatsApp" className="h-10" />
            <div className="flex flex-col gap-1">
              <a
                href="https://wa.me/message/65UIK7V2O2C3I1"
                target="_blank"
                rel="noopener noreferrer"
                className="c hover:text-orange-500"
                aria-label="Contact via WhatsApp - +91 95719 74855"
              >
                +91 95719 74855
              </a>
              <a
                href="https://wa.me/qr/CV6L3ER54KRVN1"
                target="_blank"
                rel="noopener noreferrer"
                className="c hover:text-orange-500"
                aria-label="Contact via WhatsApp - +91 63781 01238"
              >
                +91 63781 01238
              </a>
            </div>
          </div>
          <Link to="/dashboard" className="absolute top-2 md:-right-2 -right-4">
            <img src={login} alt="" />
          </Link>

          <Link to="/cart" className="flex">
            <img src={cartl} alt="Shopping Cart" className="h-14" />
            {totalItem > 0 && (
              <span className="absolute top-2 -right-2 grid h-6 w-6 place-items-center overflow-hidden rounded-full bg-red-500 border border-red-500 text-center text-sm font-bold text-white">
                {totalItem}
              </span>
            )}
          </Link>
        </div>
      </div>
      <div className="absolute right-6 bg-orange-500 p-2 rounded-full  -bottom-[30rem] md:hidden">
        <a
          href="https://wa.me/message/65UIK7V2O2C3I1"
          target="_blank"
          rel="noopener noreferrer"
          className="c hover:text-orange-500"
          aria-label="Contact via WhatsApp - +91 95719 74855"
        >
          <img src={wb} alt="WhatsApp" className="h-10" />
        </a>
      </div>
      {/* <div className=" flex md:hidden items-center gap-3 pl-3 py-2 -mt-4 border-t-2 border-gray-300 text-xs sm:text-lg sm:gap-6 justify-between mr-3  ">
        <img src={wb} alt="WhatsApp" className="h-10" />

        <div className="flex gap-6 ml-4 sm:gap-10 items-center">
          <a
            href="https://wa.me/message/65UIK7V2O2C3I1"
            target="_blank"
            rel="noopener noreferrer"
            className="c hover:text-orange-500"
            aria-label="Contact via WhatsApp - +91 95719 74855"
          >
            +91 95719 74855
          </a>
          <button
            onClick={youtubeVideo}
            className="p-2 text-white bg-youtube rounded-full font-bold hover:cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-white hover:text-youtube border hover:border-youtube hover:shadow-lg hover:rounded-full hover:border-2"
          >
            {" "}
            Youtube : Batch {new Date().toLocaleDateString()}{" "}
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default Navbar;
