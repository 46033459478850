import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import circle from "./Images/circle.svg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Card from "./Card";
import data from "../data.json";
import { Helmet } from "react-helmet";
import panchMewa from "./Images/panch_mewa.jpg";
import mishriKhopra from "./Images/mishri_khopra.jpg";
import malaiPeda from "./Images/malai_peda.webp";
import kesarPeda from "./Images/kesar_peda.avif"; // Corrected from 'avfi' to 'avif'
import laddu from "./Images/laddu.webp";
import churma from "./Images/churma.webp";
// import toast from "react-hot-toast";
// import p1 from "./Images/p1.png";
// import p2 from "./Images/p2.png";
// import { addToCartTshirt } from "../Redux/cartActions";
import shyam from "../Components/Images/shyamji.jpg";
const images = [panchMewa, malaiPeda, kesarPeda, laddu, mishriKhopra, churma];
const Home = (handleClick) => {
  const cartItems = useSelector((state) => state.cart.items || []);
  const [isVisible, setIsVisible] = useState(false);
  const [animationClass, setAnimationClass] = useState("");

  useEffect(() => {
    if (cartItems.length > 0) {
      setIsVisible(true);
      setAnimationClass("animate-fadeIn");
    } else {
      setAnimationClass("animate-fadeOut");
      setTimeout(() => setIsVisible(false), 500);
    }
  }, [cartItems]);
  // const [date, setDate] = useState(getFormattedDate());

  // useEffect(() => {
  //   // setDate(getFormattedDate());
  // }, []);

  // function getFormattedDate() {
  //   const now = new Date();
  //   const day = String(now.getDate()).padStart(2, "0");
  //   const month = String(now.getMonth() + 1).padStart(2, "0");
  //   const year = String(now.getFullYear()).slice(-2);
  //   return `${day}/${month}/${year}`;
  // }

  const language = useSelector((state) => state.language.language);
  const [fontFamily, setFontFamily] = useState("Plus Jakarta Sans");
  // const head = ["Prasadam", "प्रसादम्"];
  const quote = [
    "Choose with devotion, offered with faith",
    "श्रद्धा से चुनें, आस्था से अर्पित करें",
  ];
  const heading = ["Jai Shree Shyam", "जय श्री श्याम"];

  const [fontFamilyHeading, setFontFamilyHeading] =
    useState("Plus Jakarta Sans");

  const content = [
    "At Parambhog, we are devoted to helping you connect with the divine presence of Khatushyamji. Now, you can offer prasad to khatushyamji from anywhere anytime, we humbly offer prasad on your behalf. You simply let us know your desired offering, and we procure the prasad with care. It is then reverently presented at the holy feet of Khatushyamji. Also for transparency, we share a video proof with you. Once blessed, the prasad is lovingly packaged and sent to you, carrying the divine blessings to your home.",
    "परमभोग में हम आपको खाटू श्याम जी की दिव्य उपस्थिति से जोड़ने में समर्पित हैं। आप कभी भी ओर कहीं से भी अब खाटू नरेश को प्रसाद अर्पित कर सकते हैं आप अपने पसंदीदा प्रसाद का चयन करे, और हम आपके चयन किए हुए प्रसाद को श्रद्धा के साथ, खाटू श्याम जी के चरणों में अर्पित करते हैं। आशीर्वादित होने के बाद, ध्यानपूर्वक वह प्रसाद आपको भेजा जाता है, साथ ही आप प्रसाद अर्पित करने की वीडियो भी देख सकते है। तो आज ही परम भोग के माध्यम से खाटू नरेश को प्रसाद अर्पित करे।",
  ];
  const videoContent = [
    `Know how we offer "Prasad"`,
    `"प्रसाद" अर्पित करने की विधि को जाने।`,
  ];

  const items = data;

  const mainRef = useRef(null);

  const scrollToMain = () => {
    // handleClick("User", "Checking Prasadam", "Home", 1);
    if (mainRef.current) {
      const elementPosition =
        mainRef.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition =
        elementPosition -
        1.4 * parseFloat(getComputedStyle(document.documentElement).fontSize);
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };
  // const youtubeVideo = () => {
  //   toast("Video will be uploaded soon", {
  //     style: {
  //       background: "red",
  //       color: "white",
  //       padding: "1rem",
  //       fontWeight: "bold",
  //     },
  //   });
  // };
  // const dispatch = useDispatch();

  // const navigate = useNavigate();
  //   const handleBuyNow = (tag) => {
  //     // Redirect to payment page or handle the payment logic here
  //     dispatch(addToCartTshirt({ tag:tag }));

  //     addToCartTshirt(tag)
  //     // console.log(cartItems)
  //     navigate('/payment')
  // };
  useEffect(() => {
    // setFontFamily(language === 0 ? "Plus Jakarta Sans" : "Baloo Bhai");
    setFontFamilyHeading(language === 0 ? "Plus Jakarta Sans" : "Baloo Bhai");
  }, [language]);
  // const weightWarning = [
  //   "Total weight should be less than 2 kg!",
  //   "कुल वजन 2 किलोग्राम से कम होना चाहिए!",
  // ];
  const [isMobile, setIsMobile] = useState(false);
  // const [showFullText, setShowFullText] = useState(false); // State for toggling text visibility

  // Detect screen size (for mobile vs. laptop)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust this value based on your mobile breakpoint
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // const getFirst50Words = (text) => {
  //   const words = text.split(" ");
  //   return words.slice(0, 50).join(" ") + (words.length > 50 ? "..." : "");
  // };

  // Function to toggle the text visibility
  // const toggleText = () => {
  //   setShowFullText((prev) => !prev);
  // };

  return (
    <div className="home md:mt-0 mt-12 ">
      <Helmet>
        <title>ParamBhog</title>
        <meta
          name="description"
          content="With Parambhog, offer Prasad at Khatu Shyam Ji Temple from anywhere. We handle the offering and deliver the Prasad to your home, along with a video (where allowed)."
        />
        <meta
          name="keywords"
          content="ParamBhog, Param Bhog, Prasadam, Prasad, Khatu Shyam Ji,Khatu "
        />
      </Helmet>
      {/* <div className="absolute top-28 right-12 border-2 p-4 rounded-full">
        Checkout <FontAwesomeIcon icon={faArrowRight} />
      </div> */}
      {/* <div className="fixed z-10 text-xl font-bold top-28 right-12 border-2 border-green-500 bg-green-500 text-white p-4 rounded-full shadow-lg transition-transform duration-300 ease-in-out transform hover:bg-white hover:text-green-500 hover:shadow-2xl hover:scale-110 cursor-pointer">
        Checkout <FontAwesomeIcon icon={faArrowRight} />
      </div> */}
      {/* <div className="fixed z-10 text-xl font-bold top-28 right-12 border-2 border-green-500 bg-green-500 text-white p-4 rounded-full transition-all duration-300   shadow-[10px_10px_30px_rgba(0,0,0,0.9)] ease-in-out  transform hover:bg-white hover:text-green-500 hover:shadow-[0_10px_20px_rgba(0,0,0,0.5)] hover:scale-110 cursor-pointer">
Checkout <FontAwesomeIcon icon={faArrowRight} />
</div> */}
      {isVisible && (
        <Link
          to="/cart"
          className={`fixed z-10 md:text-xl text-lg text-center font-bold md:bottom-14 bottom-14 w-[80.69%] sm:w-auto md:left-auto md:right-32  left-9 border-2 border-green-500 bg-green-500 text-white md:p-4 p-2 rounded-xl 
          transition-all duration-300 shadow-[6px_6px_24px_rgba(0,0,0,0.9)] ease-in-out 
          transform hover:bg-white hover:text-green-500 hover:shadow-[0_10px_20px_rgba(0,0,0,0.5)] hover:scale-110 cursor-pointer ${animationClass}  md:px-16 `}
        >
          {language === 0 ? "Buy " : "खरीदे "}
          <FontAwesomeIcon icon={faArrowRight} />
        </Link>
      )}
      <div className="md:mt-24 hidden md:block mt-28 px-2  md:px-12">
        <div className="md:px-10 md:mt-10 -mt-4 px-2  -mx-1 ">
          <div className="md:text-5xl text-3xl font-semibold " ref={mainRef}>
            <h1
              className="md:text-6xl text-2xl md:h-auto h-13 pt-3 text-transparent bg-clip-text font-semibold"
              style={{
                background: "radial-gradient(circle, #FDC830, #F37335)",
                WebkitBackgroundClip: "text",
                fontFamily: fontFamilyHeading,
              }}
            >
              {heading[language]}
            </h1>
          </div>
          <div className="md:text-2xl text-sm italic mb-4 quote  mt-0">
            "{quote[language]}"
          </div>
        </div>
        <div className="flex flex-col px-2 md:px-12 md:flex-row md:gap-28 gap-8 ">
          <div
            className="md:text-[1.5rem] pt-1 md:pt-4 text-[0.9rem] md:leading-[1.8rem] leading-[1.2rem]  font-medium text-justify "
            style={{ fontFamily }}
          >
            {content[language]}
          </div>
          {/* <div
            className={`md:text-[1.5rem] ${language ===1 ?'md:leading-[2.5rem] leading-[1.2rem]' : 'md:leading-[1.8rem] leading-[1.2rem]'} pt-1 md:pt-4 text-[0.9rem]  font-medium text-justify`}
            style={{ fontFamily }}
          >
            {isMobile && !showFullText
              ? getFirst50Words(content[language])
              : content[language]}

            {isMobile &&
              !showFullText &&
              content[language].split(" ").length > 50 && (
                <button
                  onClick={toggleText}
                  className="text-blue-500 hover:underline text-[0.9rem] leading-[1.2rem] "
                >
                  Show more
                </button>
              )}

            {isMobile && showFullText && (
              <button
                onClick={toggleText}
                className="text-blue-500 hover:underline"
              >
                Show less
              </button>
            )}
          </div> */}

          <div className="flex md:flex-col flex-col-reverse md:gap-0 gap-4">
            <iframe
              src="https://www.youtube.com/embed/RIzTuwBEEYY?si=S2whCKRQHGwyn46i"
              title="YouTube video player"
              frameborder="0"
              className="rounded-3xl w-[90vw] md:mt-4 h-[40vh] mx-auto   md:w-[560px] md:h-[315px]"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>

            <h1 className="md:text-4xl text-sm md:pt-4 pt-0 font-semibold text-center text-orange-500">
              {videoContent[language]}
            </h1>
          </div>
        </div>

        <div className="hidden  md:flex md:justify-between md:gap-4">
          <button
            onClick={scrollToMain}
            className=" mt-0 md:p-4 ml-12 p-3 text-white md:text-lg text-sm bg-orange-500 rounded-full font-bold hover:cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-white hover:text-orange-500 border hover:border-orange-500 hover:shadow-lg hover:rounded-full hover:border-2"
          >
            {language === 0 ? "Offer Prasadam" : "प्रसाद अर्पित करें"}
          </button>
          {/* <button
            onClick={youtubeVideo}
            className="md:mt-16 mt-10  md:p-4 p-3 text-white md:text-lg text-sm bg-youtube rounded-full font-bold hover:cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:bg-white hover:text-youtube border hover:border-youtube hover:shadow-lg hover:rounded-full hover:border-2"
          >
            Youtube : Batch {date}
          </button> */}
        </div>
      </div>

      <div className="md:hidden">
        <img
          src={shyam}
          className="max-h-[45vh] w-full object-cover"
          alt="Shyam"
        />
        <div className="bg-orange-500 text-white p-2 text-center overflow-hidden">
          <div className="marquee-text">
            जय श्री श्याम ।। &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;एकादशी - 11/12/2024 ।।
          </div>
        </div>
      </div>

      <style jsx>{`
        .marquee-text {
          display: inline-block;
          white-space: nowrap;
          animation: marquee 20s ease-in-out infinite;
        }

        @keyframes marquee {
          0% {
            transform: translateX(130%);
          }
          50% {
            transform: translateX(-130%);
          }
          100% {
            transform: translateX(130%);
          }
        }
      `}</style>

      <div ref={mainRef} className="h-4 absolute  md:hidden"></div>
      <div ref={mainRef} className="h-12 hidden md:block"></div>
      <div ref={mainRef} className="h-4 hidden md:block"></div>
      <div className="">
        <div
          className="mt-0 py-4 md:px-4 px-0"
          // style={{
          //   minHeight: "400px",
          //   background:
          //     "linear-gradient(140deg, rgba(245, 130, 52, 0.31), rgba(245, 130, 52, 0.31))",
          // }}
        >
          <div className="relative">
            <div className="absolute -right-4 -top-4 flex items-center justify-center">
              {/* <img
                src={circle}
                alt="Circle decoration"
                className="mix-blend-color-burn opacity-50"
                style={{ width: "300px", height: "auto" }}
              /> */}
            </div>
          </div>

          {/* <div className="flex flex-wrap mt-10 md:mt-0 gap-4 justify-center">
            {items.map((item, index) => {
              const cartItem = cartItems.find((it) => it.title === item.title);
              const quantity = cartItem ? cartItem.quantity : 0;
              const updatedItem = { ...item, quantity };

              return (
                <div
                  className="md:p-6 py-2 md:m-2 mx-1 md:w-1/4 w-[45%] lg:w-1/5"
                  key={index}
                >
                  <Card
                    image={images[index]}
                    title={
                      language === 1 ? updatedItem.hTitle : updatedItem.title
                    }
                    netWeight={updatedItem.netWeight}
                    ite={updatedItem}
                  />
                </div>
              );
            })}
          </div> */}
          <div className="flex flex-wrap mt-10 md:mt-0 md:gap-4 gap-1 justify-center">
            {items
              .reduce((acc, item) => {
                // Find all cart items that match the current item's title
                const cartItemsForTitle = cartItems.filter(
                  (it) => it.title === item.title
                );

                // If there are cart items for this title, add them to the accumulator
                if (cartItemsForTitle.length > 0) {
                  const pricesAndQuantities = cartItemsForTitle.map(
                    (cartItem) => ({
                      price: cartItem.price, // Assuming each item has a price field
                      quantity: cartItem.quantity,
                    })
                  );

                  acc.push({
                    ...item,
                    pricesAndQuantities,
                  });
                } else {
                  // If no cart items match, you could still push a default or skip
                  acc.push({
                    ...item,
                    pricesAndQuantities: [{ price: 0, quantity: 0 }], // or handle as needed
                  });
                }

                return acc;
              }, [])
              .map((updatedItem, index) => (
                <div
                  className="md:p-6 py-2 md:m-2 mx-1 md:w-1/4 w-[45%] lg:w-1/5"
                  key={index}
                >
                  <Card
                    image={images[index]} // Ensure this corresponds correctly
                    title={updatedItem.title}
                    netWeight={updatedItem.netWeight}
                    priceQuantityData={updatedItem.pricesAndQuantities} // Array of objects with price and quantity
                    ite={updatedItem}
                  />
                </div>
              ))}
          </div>

          {/* <div className="flex flex-wrap justify-center">
            <div className="flex flex-col items-center m-2">
                <img src={p1} alt="Product 1" className="w-full h-auto max-w-sm" />
                <p className="mt-2 text-lg font-semibold">₹499</p>
                <button 
                    onClick={() => handleBuyNow('orange')} 
                    className="mt-2 bg-blue-500 text-white py-2 px-4 rounded"
                >
                    Pay Now
                </button>
            </div>
            <div className="flex flex-col items-center m-2">
                <img src={p2} alt="Product 2" className="w-full h-auto max-w-sm" />
                <p className="mt-2 text-lg font-semibold">₹499</p>
                <button 
                    onClick={() => handleBuyNow('black')} 
                    className="mt-2 bg-blue-500 text-white py-2 px-4 rounded"
                >
                    Pay Now
                </button>
            </div>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
