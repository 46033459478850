import axios from "axios";
// import { SaveExcel } from "./SaveExcel";
import toast from "react-hot-toast";
import { setUserPay } from "../Redux/userActions";

export async function PayCod(
  formData,
  cartItems,
  dataToSubmit,
  deliveryCharge,
  totalAmount,
  user,
  setFormData,
  token,
  navigate,
  dispatch,
  abortController
) {
  const total = totalAmount; // Ensure totalAmount is passed correctly
  const toastId = toast.loading("Loading...");

  try {
    // Dispatching the payment information to Redux
    dispatch(setUserPay(formData));

    // Making the API call to process the payment
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL_IVAR_NOT_KNOWN}/api/paymentcod`,
      {
        formData,
        cartItems,
        token,
        user,
        total,
        dataToSubmit,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
        signal: abortController.signal,
      }
    );

    // Check if the response indicates success
    if (response.data.success) {
      // Adjusted to access the success property correctly
      toast.success(
        "Thank you for your order 🙏. We will reach out to you soon."
      );
      return "success";
    } else {
      throw new Error(response.data.message || "Payment processing failed");
    }
  } catch (error) {
    // Handle errors more gracefully
    toast.error(error.message || "Unknown error");
  } finally {
    toast.dismiss(toastId); // Dismiss the loading toast
  }
}
