import { ADD_TO_CART } from "./cartActions";
import { ADD_TO_CART_TSHIRT } from "./cartActions";

const setCookie = (name, value, days) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${expires}; path=/`;
};

const getCookie = (name) => {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  return match ? decodeURIComponent(match[2]) : null;
};

let initialState = {
  items: [],
  totalWeight: 0,
};

const cookieValue = getCookie("cartItems");

if (cookieValue) {
  try {
    const parsedCookieValue = JSON.parse(cookieValue);
    initialState.items = parsedCookieValue.items || [];
    initialState.totalWeight = parsedCookieValue.totalWeight || 0;
  } catch (error) {
    // console.error("Failed to parse cart items from cookie:");
  }
}

const setCartCookie = (cartItems, totalWeight, totalPrice) => {
  const cookieValue = JSON.stringify({
    items: cartItems,
    totalWeight,
    totalPrice,
  });
  setCookie("cartItems", cookieValue, 7);
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART: {
      const existingItemIndex = state.items.findIndex(
        (item) => ((item.title === action.payload.title) && (item.price === action.payload.price))
      );
      let updatedItems;
      if (existingItemIndex !== -1) {
        const updatedItem = {
          ...state.items[existingItemIndex],
          quantity: action.payload.quantity,
          price: action.payload.price,
          netWeight: action.payload.netWeight,
        };

        updatedItems = [
          ...state.items.slice(0, existingItemIndex),
          updatedItem,
          ...state.items.slice(existingItemIndex + 1),
        ];
      } else {
        updatedItems = [...state.items, { ...action.payload, quantity: 1 }];
      }
      updatedItems = updatedItems.filter((item) => item.quantity > 0);
      const newTotalWeight = updatedItems.reduce((total, item) => {
        return total + item.quantity * item.price * item.netWeight;
      }, 0);

      setCartCookie(updatedItems, newTotalWeight);
      return {
        items: updatedItems,
        totalWeight: newTotalWeight,
      };
    }
    case ADD_TO_CART_TSHIRT: {
      const existingItemIndex = state.items.findIndex(
          (item) => ((item.tag === action.payload.tag) && (item.price === action.payload.price))
      );
      // console.log(1)
      let updatedItems;
  
      if (existingItemIndex !== -1) {
          // Update the quantity instead of resetting it
          const updatedItem = {
              ...state.items[existingItemIndex],
              quantity:  1, // Increment quantity
          };
  
          updatedItems = [
              ...state.items.slice(0, existingItemIndex),
              updatedItem,
              ...state.items.slice(existingItemIndex + 1),
          ];
      } else {
          // Add a new item with quantity 1
          updatedItems = [
              ...state.items,
              { tag: action.payload.tag, price: 499, quantity: 1 },
          ];
      }
  
      // Filter out items with zero quantity (if applicable)
      updatedItems = updatedItems.filter((item) => item.quantity > 0);
  
      // Example: Calculate total weight (if needed)
      const newTotalWeight = updatedItems.reduce((total, item) => {
          // Assuming each item has a weight property, adjust as necessary
          return total + (item.weight || 0) * item.quantity;
      }, 0);
  
      // console.log(updatedItems);
      setCartCookie(updatedItems, newTotalWeight);
  
      return {
          items: updatedItems,
          totalWeight: newTotalWeight,
      };
  }
  
    default:
      return state;
  }
};

export default cartReducer;
