// src/redux/languageReducer.js
import { TOGGLE_LANGUAGE } from './languageActions';

const initialState = { language: 0 };

const languageReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_LANGUAGE:
            return { ...state, language: 1 - state.language };
        default:
            return state;
    }
};

export default languageReducer;
