import React from "react";
import { useSelector } from "react-redux";

// Helper function to format date
const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const PaymentItem = ({ payment }) => {
  const language = useSelector((state) => state.language.language);

  let cartItems = [];
  if (payment.cartItems) {
    try {
      cartItems = JSON.parse(payment.cartItems);
    } catch (error) {
      // console.error("Error parsing cart items:", error);
    }
  }

  return (
    <div key={payment.id} className="mb-4 border p-2 rounded">
      <p>
        <strong>Total Amount:</strong> ₹{payment.total || "Not Available"}
      </p>
      <p>
        <strong>Ordered At:</strong> {formatDate(payment.createdAt)}
      </p>
      <p>
        <strong>Items:</strong>
      </p>
      {Array.isArray(cartItems) && cartItems.length > 0 ? (
        cartItems.map((item) => (
          <div key={item.id || item.title} className="ml-4 mb-2">
            <p>
              <strong>Item Name:</strong>{" "}
              {language === 0 ? item.title : item.hTitle}
            </p>
            <p>
              <strong>Quantity:</strong> {item.quantity}
            </p>
            <p>
              <strong>Price:</strong> ₹{item.price}
            </p>
          </div>
        ))
      ) : (
        <p>No items in the cart</p>
      )}
    </div>
  );
};

export default PaymentItem;
