// src/redux/cartActions.js
export const ADD_TO_CART = 'ADD_TO_CART';

export const addToCart = (item) => ({
    type: ADD_TO_CART,
    payload: item,
});
export const ADD_TO_CART_TSHIRT = 'ADD_TO_CART_TSHIRT';

export const addToCartTshirt = (tag) => ({
    type: ADD_TO_CART_TSHIRT,
    payload: tag,
});

