import axios from "axios";
import toast from "react-hot-toast";



// Action types
export const SET_USER = "SET_USER";
export const SET_USER_PAY = "SET_USER_PAY";
export const CLEAR_USER = "CLEAR_USER";

// Action to set user and token in Redux store
export const setUser = (user, token) => ({
  type: SET_USER,
  payload: { user, token },
});

export const setUserPay = (formData) => ({
  type: SET_USER_PAY,
  payload: { formData },
});

// Action to clear user and token from Redux store
export const clearUser = () => ({
  type: CLEAR_USER,
});

// Login with email and password
export const loginWithEmail = (email, password) => async (dispatch) => {
  toast.loading('Please Wait ...');

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL_IVAR_NOT_KNOWN}/api/auth/email`, {
      email,
      password,
    });
    const { user, token } = response.data; 
    dispatch(setUser(user, token));
    toast.success('Login successful!'); // Notify success
  } catch (error) {
    const errorMessage = error.response?.data?.message || "Login failed. Please try again.";
    toast.error(errorMessage); // Notify error
    // console.error("Login Error:", errorMessage);
  } finally {
    toast.dismiss();
  }
};

// Login with Google
export const loginWithGoogle = (tokenId) => async (dispatch) => {
  toast.loading('Please wait...');

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL_IVAR_NOT_KNOWN}/api/auth/google`, {
      tokenId,
    });
    const { user, token } = response.data; 
    dispatch(setUser(user, token));
    toast.success('Login successful!'); 
  } catch (error) {
    const errorMessage = "Google login failed. Please try again.";
    toast.error(errorMessage);
    // console.error("Google Login Error:", errorMessage);
  } finally {
    toast.dismiss();
  }
};

// Logout action
export const logout = () => (dispatch) => {
  // Dispatch clearUser action to remove user and token from Redux
  dispatch(clearUser());
  toast.success('Logged out successfully!'); // Notify logout
};
